<template>
  <v-timeline :dense="$vuetify.breakpoint.xs">
    <v-timeline-item fill-dot icon="mdi-equal" large right>
      <template v-slot:opposite>
        <h2 class="headline font-weight-light">
          <DateValue long :value="lastImport.date" />
        </h2>
        {{ formatTime(lastImport.time) }}
      </template>
      <div class="py-4">
        <h2 class="headline font-weight-light" v-if="$vuetify.breakpoint.xs">
          <DateValue long :value="lastImport.date" />, {{ lastImport.time }}
        </h2>

        <span class="headline font-weight-bold primary--text">
          CHF {{ balance.toFixed(2) }}
        </span>

        <div>Kontostand letzter Import</div>
      </div>
    </v-timeline-item>
    <v-timeline-item
      v-for="(item, index) in transactions"
      :key="index"
      fill-dot
      :icon="item.amount > 0 ? 'mdi-plus-thick' : 'mdi-minus-thick'"
      :color="item.amount > 0 ? 'success' : 'error'"
      right
    >
      <template v-slot:opposite>
        <h2 class="headline font-weight-light">
          <DateValue long :value="item.date" />
        </h2>
        {{ item.time }}
      </template>
      <div class="py-4">
        <h2 class="headline font-weight-light" v-if="$vuetify.breakpoint.xs">
          <DateValue long :value="item.date" />, {{ item.time }}
        </h2>
        <span
          :class="`headline font-weight-bold ${
            item.amount > 0 ? 'success' : 'error'
          }--text`"
        >
          CHF {{ Math.abs(item.amount).toFixed(2) }}
        </span>

        <div v-html="item.description"></div>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import { formatTime } from "common/utils/date.js";
export default defineComponent({
  components: { DateValue },
  data() {
    return {
      loading: false,
      balance: 0,
      lastImport: {},
      transactions: [],
    };
  },
  methods: {
    formatTime,
    async fetchData() {
      const items = await this.apiList({
        resource: "finance/printtransaction",
        query: `student=${this.$_profilePerson.id}`,
      });
      this.balance = items.length == 0 ? 0.0 : items[items.length - 1].balance;
      this.transactions = items.reverse();
      this.lastImport = await this.apiList({
        resource: "finance/printimportstate",
      });
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
